import {mapActions, mapGetters} from "vuex";
import {dynamicHead} from '@/mixins/dynamic-head.js'

export default {
  name: "sitemap",
  mixins: [dynamicHead],
  components:{

  },
  data(){
    return{
      params: {
        title: '',
        description: '',
        keywords: '',
        image: '',
      },
    }
  },
  created() {
    this.setMeta()
  },
  computed:{
    ...mapGetters({
      allCategories: 'category/categories',
    })
  },

  methods:{
    ...mapActions({
    }),
    setMeta() {
      this.$emit('updateHead');
    },
  }
}